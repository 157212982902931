import {
  EBattlesListSortingDirections,
  EBattlesListSortingVariants,
  EBattlesStoreNames,
} from '~/features/battles/constants/general';
import type { IBattlesListFilters, IBattlesListSorting } from '~/features/battles/types/battlesStore.types';

const defaultFiltersValues = {
  availableByBalance: false,
  hideActive: false,
};

const defaultSortingValues = {
  sortBy: EBattlesListSortingVariants.PRICE,
  sortDesc: EBattlesListSortingDirections.FROM_DOWN_TO_UP,
};

/* Хранилище состояния сортировки и фильтров, связанных со списком батлов */
export const useBattlesListFiltersSortingStore = defineStore(EBattlesStoreNames.BATTLES_LIST_FILTERS, () => {
  /* -- Initialisation -- */
  const filters = reactive<IBattlesListFilters>({ ...defaultFiltersValues });
  const sorting = reactive<IBattlesListSorting>({ ...defaultSortingValues });

  /* -- Methods -- */
  /* Функция очистки состояний сортировки и фильтров до исходных значений */
  const clearFilters = () => {
    Object.assign(filters, { ...defaultFiltersValues } as IBattlesListFilters);
    Object.assign(sorting, { ...defaultSortingValues } as IBattlesListSorting);
  };

  /* -- Returns -- */
  return {
    clearFilters,
    filters,
    sorting,
  };
});
